<template>
	<b-modal
		id="tac-modal"
		class="defaultModal"
		centered
		:title="t('common.tac')"
		v-model="showModal"
		@show="initModal"
		@hide="hideModal"
		:model-ok="false"
	>
		<b-row class="verifieContent">
			<b-col>
				<form id="otpForm" class="fieldContainerModal" @submit.prevent="onSubmitTac" novalidate>
					<InputOtp
						v-model="tacCode"
						:label="t('common.verificationCode')"
						:required="true"
						:errors="v$.tac.tacCode.$errors"
					/>
				</form>
			</b-col>
		</b-row>
		<template #footer>
			<div class="buttonsContainer">
				<!-- "key" directive helps refresh the ".once" event modifier -->
				<!-- once the coundown starts -->
				<button class="secondaryButton" :disabled="timeRemaining" @click.once="requestTAC(true)" :key="timeRemaining">
					{{ timeRemaining ? t("common.resendInSeconds", { time: timeRemaining }) : t("common.resendTac") }}
				</button>

				<button type="submit" form="otpForm" class="mainButton">
					{{ t("common.submit") }}
				</button>
			</div>
		</template>
	</b-modal>
</template>
<script setup>
import { ref, computed, defineProps, nextTick, defineEmits, defineExpose, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength, numeric, maxLength } from "@vuelidate/validators";
import InputOtp from "@/components/dynamic/Inputs/InputOtp.vue";
import { useIsMobile } from "@/composables/useMobile";
import { useToast } from "vue-toastification";
import { toastOptionSuccess, toastOptionError } from "@/composables/useToastOptions";
import { isLogin, authField } from "@/composables/useAuth";
import { useLoading } from "vue-loading-overlay";

const { t, locale } = useI18n();
const store = useStore();
const router = useRouter();
const route = useRoute();
const isMobile = useIsMobile();
const toast = useToast();
const $loading = useLoading();

const props = defineProps({
	loginId: {
		type: String,
		default: null,
	},
	isMigrated: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits(["reLogin"]);

// ================= TAC Start =================

const tacCode = ref("");
const loginId = ref(props.loginId);
const onSubmitTac = async () => {
	const validity = await v$.value.tac.$validate();
	if (!validity) return;

	const loader = $loading.show();

	if (props.isMigrated) {
		const result = await requestForgotPassword();
		if (result?.url) {
			endModal();
			window.open(result?.url);
		}
		loader.hide();
		return;
	}
	if (
		// prettier-ignore
		await verifyTAC()
	) {
		// insert success action here
		emit("reLogin");
		endModal();
	}

	loader.hide();
};

const requestTAC = async () => {
	return await store
		.dispatch("register/requestTAC", {
			phoneNo: props.loginId,
			password: "",
		})
		.then(() => {
			toast.success(t("toast.tacSuccess"), toastOptionSuccess);
			startCountdown();
		})
		.catch(() => {
			toast.error(t("toast.tacFailed"), toastOptionError);
			startCountdown(5);
		});
};

const verifyTAC = async () => {
	return await store
		.dispatch("register/verifyTAC", {
			phoneNo: props.loginId,
			token: tacCode.value,
		})
		.then(() => {
			toast.success(t("toast.submitSuccess"), toastOptionSuccess);
			return true;
		})
		.catch(() => {
			toast.error(t("toast.tacVerificationFailed"), toastOptionError);
			return false;
		});
};

const requestForgotPassword = async () => {
	return await store
		.dispatch("member/forgotPassword", {
			phoneNo: props.loginId,
			byPassSms: true,
			tokenOTP: tacCode.value,
		})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			toast.error(t("toast.tacVerificationFailed"), toastOptionError);
			return false;
		});
};

const TAC_COOLDOWN = 60;
const timeRemaining = ref(0);

const startCountdown = async (cooldown) => {
	timeRemaining.value = cooldown || TAC_COOLDOWN;
	const timer = setInterval(() => {
		timeRemaining.value -= 1;
		if (timeRemaining.value <= 0) clearInterval(timer);
	}, 1000);
};

const clearForm = async () => {
	tacCode.value = "";

	await nextTick();
	v$.value.$reset();
};
// ================= TAC End =================

// ================= Modal Control Start =================

const showModal = ref(false);

const openModal = () => {
	showModal.value = true;
	requestTAC();
};

const hideModal = () => {
	showModal.value = false;
};

const initModal = async () => {
	return;
};

const endModal = () => {
	hideModal();
	return;
};

// ================= Modal Control End =================

// ================= Validation Start =================
const TAC_LENGTH = 6;

const rules = computed(() => ({
	tac: {
		tacCode: {
			required: helpers.withMessage(() => t("validation.required"), required),
			minLength: helpers.withMessage(
				({ $params }) => t("validation.minLength", { minLength: $params.min }),
				minLength(TAC_LENGTH)
			),
		},
	},
}));

const v$ = useVuelidate(rules, {
	tac: { tacCode },
});

// ================= Validation End =================

defineExpose({ openModal });
</script>

<style scoped lang="sass">
.buttonsContainer
  display: flex
  flex-direction: column
  gap: 1rem
.forgotPasswordButton,
.backButton
  width: fit-content
  margin-inline: auto
  text-decoration: underline
  color: var(--text-muted)
.basicTabsContainer
  background: var(--background-primary)
  border-radius: 10px
  margin: 0 auto 1rem
  display: flex
  width: max-content
.tabList
  display: flex
  justify-content: center
  align-items: center
  row-gap: 0.5rem
  padding: 5px
  overflow-x: scroll
.tabItem
  font-weight: var(--font-bold)
  text-align: center
  // font-size: 0.9em
  padding: 0.5rem 2rem
  cursor: pointer
  flex-grow: 1
  border-radius: 5px
  &.active
    background: var(--background-primary)
    color: var(--text-accent)

@media (max-width: 767px)
  .tabList
    justify-content: start
    align-items: end
    flex-wrap: revert
    overflow: auto
  .tabItem
    min-width: auto
    line-height: 1em
    padding-inline: 2rem
</style>
