<template>
	<div class="mobileDockContainer">
		<div class="dockList">
			<template v-for="nav in filteredDockList" :key="nav.title">
				<UnifiedLink
					:isRouteLink="nav.isRouteLink"
					:link="nav.link"
					:isModal="nav.isModal"
					:modalName="nav.modalName"
					:isEvent="nav.isEvent"
					:event="nav.event"
					:validateLogin="nav.validateLogin"
					:validateBank="nav.validateBank"
					@linkClick="handleEvent(nav.event)"
				>
					<div class="dockItem" :class="{ main: nav.title == MAIN_NAV }">
						<img
							class="dockItemIcon"
							v-if="nav.title != MAIN_NAV"
							:src="route.name == nav.title ? imgUrlDomain + nav.imgActive : imgUrlDomain + nav.img"
						/>
						<!-- <base-image v-else :imgSrc="logo" class="dockLogo"></base-image> -->
						<div v-if="nav.title != MAIN_NAV" class="dockItemText">
							{{ t(`route.${nav.title}`) }}
						</div>
					</div>
				</UnifiedLink>
			</template>
		</div>
	</div>
</template>

<script setup>
import { ref, computed } from "vue";
import { routeData } from "@/constant";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { toastOptionSuccess, toastOptionError } from "@/composables/useToastOptions";
import store from "@/store/index";
import UnifiedLink from "@/components/dynamic/UnifiedLink.vue";

const { t, locale } = useI18n();
const router = useRouter();
const route = useRoute();
const toast = useToast();

const imgUrlDomain = process.env.VUE_APP_Media_Domain;
const logo = "assets/images/NavBar/ace28_rounded.png";
const logoUrl = `url(${imgUrlDomain + logo})`;

// ================= List Start =================

// !!! Check if name exists in constant.ts !!!
const MAIN_NAV = "home";
const MAIN_ROUTE_DATA = {
	title: "home",
	event: "home",
	isEvent: true,
};

const contactNav = computed(() => (liveChat.value ? "livechat" : "contact"));
const dockList = computed(() => ["promotion", contactNav.value, MAIN_NAV, "statement", "myaccount"]);
const filteredDockList = computed(() =>
	dockList.value.reduce((newList, title) => {
		if (title == MAIN_NAV) newList.push(MAIN_ROUTE_DATA);
		else newList.push(routeData.find((nav) => nav.title == title));
		return newList;
	}, [])
);

const liveChat = computed(() =>
	store.getters["brand/getBrand"]?.find((contact) => contact?.brandName?.toLowerCase() == "livechat")
);
const openLivechat = () =>
	liveChat.value
		? window.open(liveChat.value?.redirectURL, "_blank")
		: toast.error(t("toast.urlNotFound"), toastOptionError);

const handleEvent = (event) => {
	if (event == "home") router.push("/");
	if (event == "livechat") openLivechat();
	return;
};

// ================= List End =================
</script>

<style scoped lang="sass">
.mobileDockContainer
	display: block
	position: fixed
	bottom: 0
	height: var(--mobile-dock-height)
	width: 100%
	z-index: 30
	background: var(--gradient-accent)
	box-shadow: var(--box-shadow)
	margin: 0 auto

.dockList
	display: flex
	justify-content: space-evenly
	align-items: center
	height: 100%
	width: 100%
.dockItem
	display: flex
	flex-direction: column
	align-items: center
	width: 4rem
	gap: 0.5rem
	&.main
		width: 5rem
		height: 5rem
		display: flex
		justify-content: center
		align-items: center
		transform: translate(0, -15px)
		border-radius: 50%
		// background: var(--color-accent)
		background-image: v-bind('logoUrl')
		background-repeat: no-repeat
		background-position: center
		background-size: calc(100% + 4px)
		color: var(--text-inverted)
		user-select: none
		box-shadow: 0 3px 10px 1px #00000077
		&:active
			transform: translate(0, -12px)
			box-shadow: 0 1px 6px 0 #00000077
		// .dockItemIcon
		// 	font-size: 2em
// :deep(.router-link-active .dockItem:not(.main))
// 	color: var(--text-accent)
.dockItemIcon
	font-size: 1.5em
	height: 1.5rem
	filter: brightness(0) invert(1)
.dockItemText
	font-weight: var(--font-medium)
	text-align: center
	line-height: 0.9em
	font-size: 0.65em
	color: var(--text-inverted)
	padding-inline: 1rem
	text-transform: uppercase
	text-wrap: nowrap

.dockLogo
	border-radius: 50%
</style>
