interface routeDataItem {
	title: string;
	link: string;
	modalName: string;
	event: string;
	icon: string[];
	isRouteLink: boolean;
	isModal: boolean;
	isEvent: boolean;
	validateLogin: boolean;
	validateBank: boolean;
	guestOnly: boolean;
	img: string;
	imgActive: string;
}

export const projectName = "ACE28";

export const isFeatureEnabled = {
	VIP: true,
	REBATE: true,
	MINIGAME: true,
	REGISTER: true,
	REFERRAL: true,
	REFERRAL_CLAIM: true,
	CHECKIN: false,
	AGENT: false,
	RESCUE: false,
	FORCE_AUTO_TRANSFER: true,
};

export const routeData: routeDataItem[] = [
	{
		title: "home",
		link: "/",
		modalName: null,
		event: "",
		icon: ["fas", "house"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: false,
		img: "assets/images/NavBar/NavGames.png",
		imgActive: "assets/images/NavBar/NavGamesActive.png",
	},
	{
		title: "login",
		link: "/login",
		modalName: "login-modal",
		event: "",
		icon: ["fas", "circle-user"],
		isRouteLink: false,
		isModal: true,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: true,
		img: "",
		imgActive: "",
	},
	{
		title: "register",
		link: "/register",
		modalName: "register-modal",
		event: "",
		icon: ["fas", "user-plus"],
		isRouteLink: false,
		isModal: true,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: true,
		img: "",
		imgActive: "",
	},
	{
		title: "forgotpassword",
		link: "/member/forgotpassword",
		modalName: "forgotPassword-modal",
		event: "",
		icon: ["fas", "circle-question"],
		isRouteLink: true,
		isModal: true,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: true,
		img: "",
		imgActive: "",
	},
	{
		title: "slots",
		link: "/slots",
		modalName: null,
		event: "",
		icon: ["fas", "gamepad"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	{
		title: "casino",
		link: "/casino",
		modalName: null,
		event: "",
		icon: ["fas", "dice"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	{
		title: "fishing",
		link: "/fishing",
		modalName: null,
		event: "",
		icon: ["fas", "fish"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	{
		title: "sports",
		link: "/sports",
		modalName: null,
		event: "",
		icon: ["far", "futbol"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	{
		title: "lottery",
		link: "/lottery",
		modalName: null,
		event: "",
		icon: ["far", "7"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	// {
	// 	title: "fastgame",
	// 	link: "/fastgame",
	// 	modalName: null,
	// 	event: "",
	// 	icon: ["fas", "plane"],
	// 	isRouteLink: true,
	// 	isModal: false,
	// 	isEvent: false,
	// 	validateLogin: false,
	// 	validateBank: false,
	// 	guestOnly: false,
	// 	img: "",
	// 	imgActive: "",
	// },
	{
		title: "promotion",
		link: "/promotion",
		modalName: "promo-modal",
		event: "",
		icon: ["fas", "gift"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: false,
		img: "assets/images/NavBar/NavEvents.png",
		imgActive: "assets/images/NavBar/NavEventsActive.png",
	},
	{
		title: "aboutus",
		link: "/aboutus",
		modalName: null,
		event: "",
		icon: ["fas", "circle-info"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	{
		title: "contact",
		link: "/contact",
		modalName: null,
		event: "",
		icon: ["fas", "phone"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: false,
		img: "assets/images/NavBar/NavContact.png",
		imgActive: "assets/images/NavBar/NavContactActive.png",
	},
	{
		title: "livechat",
		link: "",
		modalName: null,
		event: "livechat",
		icon: ["fas", "comments"],
		isRouteLink: false,
		isModal: false,
		isEvent: true,
		validateLogin: false,
		validateBank: false,
		guestOnly: false,
		img: "assets/images/NavBar/NavContact.png",
		imgActive: "assets/images/NavBar/NavContactActive.png",
	},
	{
		title: "terms",
		link: "/terms",
		modalName: "terms-modal",
		event: "",
		icon: ["fas", "clipboard-list"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	{
		title: "privacypolicy",
		link: "/privacypolicy",
		modalName: "privacypolicy-modal",
		event: "",
		icon: ["fas", "shield-halved"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	{
		title: "responsiblegaming",
		link: "/responsiblegaming",
		modalName: null,
		event: "",
		icon: ["far", "handshake"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	{
		title: "vip",
		link: "/vip",
		modalName: null,
		event: "",
		icon: ["fas", "crown"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: true,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	// {
	// 	title: "interchange",
	// 	link: "interchange-modal",
	// 	modalName: null,
	// 	event: "",
	// 	icon: ["fas", "shuffle"],
	// 	isRouteLink: false,
	// 	isModal: true,
	// 	isEvent: false,
	// 	validateLogin: false,
	// 	validateBank: false,
	// 	guestOnly: false,
	// 	img: "",
	// 	imgActive: "",
	// },
	{
		title: "myaccount",
		link: "/settings",
		modalName: null,
		event: "",
		icon: ["fas", "gear"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: false,
		img: "assets/images/NavBar/NavSettings.png",
		imgActive: "assets/images/NavBar/NavSettingsActive.png",
	},
	{
		title: "profile",
		link: "/profile",
		modalName: null,
		event: "",
		icon: ["fas", "id-card"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: true,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	{
		title: "bankinfo",
		link: "/bankinfo",
		modalName: null,
		event: "",
		icon: ["fas", "building-columns"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: true,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	{
		title: "referral",
		link: "/referral",
		modalName: null,
		event: "",
		icon: ["fas", "users"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: true,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	// {
	// 	title: "bonus",
	// 	link: "/bonus",
	// 	modalName: null,
	// 	event: "",
	// 	icon: ["fas", "award"],
	// 	isRouteLink: true,
	// 	isModal: false,
	// 	isEvent: false,
	// 	validateLogin: true,
	// 	validateBank: false,
	// 	guestOnly: false,
	// 	img: "",
	// 	imgActive: "",
	// },
	{
		title: "deposit",
		link: "/deposit",
		modalName: null,
		event: "",
		icon: ["fas", "circle-dollar-to-slot"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: true,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	{
		title: "withdrawal",
		link: "/withdrawal",
		modalName: null,
		event: "",
		icon: ["fas", "hand-holding-dollar"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: true,
		validateBank: true,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	{
		title: "statement",
		link: "/statement",
		modalName: null,
		event: "",
		icon: ["fas", "file-invoice-dollar"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: true,
		validateBank: false,
		guestOnly: false,
		img: "assets/images/NavBar/NavStatement.png",
		imgActive: "assets/images/NavBar/NavStatementActive.png",
	},
	{
		title: "changepassword",
		link: "/changepassword",
		modalName: null,
		event: "",
		icon: ["fas", "user-lock"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: true,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	{
		title: "language",
		link: "/language",
		modalName: null,
		event: "",
		icon: ["fas", "earth-americas"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	{
		title: "theme",
		link: "/theme",
		modalName: null,
		event: "",
		icon: ["fas", "circle-half-stroke"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	{
		title: "logout",
		link: "",
		modalName: null,
		event: "logout",
		icon: ["fas", "right-from-bracket"],
		isRouteLink: false,
		isModal: false,
		isEvent: true,
		validateLogin: true,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	// {
	// 	title: "scan",
	// 	link: "/scan",
	// 	modalName: null,
	// 	event: "scan",
	// 	icon: ["fas", "camera"],
	// 	isRouteLink: true,
	// 	isModal: false,
	// 	isEvent: false,
	// 	validateLogin: false,
	// 	validateBank: false,
	// 	guestOnly: true,
	// 	img: "",
	// 	imgActive: "",
	// },
	{
		title: "qrcode",
		link: "/qrcode",
		modalName: null,
		event: "",
		icon: ["fas", "qrCode"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: true,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	{
		title: "checkin",
		link: "/checkin",
		modalName: "",
		event: "",
		icon: ["far", "calendar-check"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: true,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	// {
	// 	title: "interest",
	// 	link: "/interest",
	// 	modalName: "",
	// 	event: "",
	// 	icon: ["fas", "money-bill-trend-up"],
	// 	isRouteLink: true,
	// 	isModal: false,
	// 	isEvent: false,
	// 	validateLogin: true,
	// 	validateBank: false,
	// 	guestOnly: false,
	// 	img: "",
	// 	imgActive: "",
	// },
	// {
	// 	title: "cashin",
	// 	link: "/interest/cashin",
	// 	modalName: "",
	// 	event: "",
	// 	icon: ["fas", "sack-dollar"],
	// 	isRouteLink: true,
	// 	isModal: false,
	// 	isEvent: false,
	// 	validateLogin: true,
	// 	validateBank: false,
	// 	guestOnly: false,
	// 	img: "",
	// 	imgActive: "",
	// },
	// {
	// 	title: "cashout",
	// 	link: "/interest/cashout",
	// 	modalName: "",
	// 	event: "",
	// 	icon: ["fas", "hand-holding-dollar"],
	// 	isRouteLink: true,
	// 	isModal: false,
	// 	isEvent: false,
	// 	validateLogin: true,
	// 	validateBank: false,
	// 	guestOnly: false,
	// 	img: "",
	// 	imgActive: "",
	// },
	{
		title: "inbox",
		link: "/inbox",
		modalName: "",
		event: "",
		icon: ["fas", "envelope"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: true,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	{
		title: "download",
		link: "/download",
		modalName: "",
		event: "",
		icon: ["fas", "download"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	// {
	// 	title: "interchange",
	// 	link: "interchange-modal",
	// 	modalName: null,
	// 	event: "",
	// 	icon: ["fas", "shuffle"],
	// 	isRouteLink: false,
	// 	isModal: true,
	// 	isEvent: false,
	// 	validateLogin: false,
	// 	validateBank: false,
	// 	guestOnly: false,
	// },
	// {
	// 	title: "quicktransfer",
	// 	link: "/quicktransfer",
	// 	modalName: null,
	// 	event: "",
	// 	icon: ["fas", "forward"],
	// 	isRouteLink: true,
	// 	isModal: false,
	// 	isEvent: false,
	// 	validateLogin: true,
	// 	validateBank: true,
	// 	guestOnly: false,
	// 	img: "",
	// 	imgActive: "",
	// },
	{
		title: "transfer",
		link: "/transfer",
		modalName: null,
		event: "",
		icon: ["fas", "money-bill-transfer"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: true,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
	{
		title: "search",
		link: "/search",
		modalName: null,
		event: "",
		icon: ["fas", "search"],
		isRouteLink: true,
		isModal: false,
		isEvent: false,
		validateLogin: false,
		validateBank: false,
		guestOnly: false,
		img: "",
		imgActive: "",
	},
];
